import { convertFromHTML } from "draft-convert";
import {
  AtomicBlockUtils,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { isEmpty, omitBy } from "lodash";
import { v4 as uuidv4 } from "uuid";

import {
  blockTypeEnum,
  draftJsEntityTypeEnum,
  editorChangeTypeEnum,
  entityTypeEnum,
} from "@/enums/editor";
import { fileUploadStatusEnum } from "@/enums/fileEnum";
import { MESSAGE_EDITOR_DEFAULT_CHAR_LIMIT } from "@/settings";
import * as editorHelperUtils from "@/utils/editorHelperUtils";
import { validateNonEmptyString } from "@/utils/formValidationUtils";
import { sanitizeString } from "@/utils/textUtils";

class EditorHelper {
  constructor({ editor }) {
    this.editor = editor;
  }

  /* Responsible for creating an instance of an editor/tab in the RTE */
  static createEditor = ({
    id,
    label = "",
    backgroundColor = "white",
    placeholder = "",
    characterLimit = MESSAGE_EDITOR_DEFAULT_CHAR_LIMIT,
    fileLimit = 10,
    editorLimit = 10,
    isViewOnly = false,
    actionIconSet = [],
    editorStates,
    isMentionsEnabled = false,
    ...otherOptions
  } = {}) => {
    const editor = {
      id: id || uuidv4(),
      label,
      editorStates: editorStates || [EditorState.createEmpty()],
      backgroundColor,
      placeholder,
      characterLimit,
      fileLimit,
      editorLimit,
      isViewOnly,
      actionIconSet,
      isMentionsEnabled,
      ...otherOptions,
    };

    return editor;
  };

  /* Responsible for creating an EditorState object from raw draft.js data  */
  static convertEditorStateFromRaw = ({ rawEditorState }) => {
    const contentState = convertFromRaw(rawEditorState);
    return EditorState.createWithContent(contentState);
  };

  /*
    Message is valid only when it has at least one block with content
    -> This function should be considered when dealing with editor performance
  */
  static isMessageValid({ editorState }) {
    const blockArray = editorState.getCurrentContent().getBlocksAsArray();

    const hasSomeText = blockArray.some((block) =>
      validateNonEmptyString(block.getText()),
    );

    if (hasSomeText) return true;

    const mediaEntities = EditorHelper.getEntities({
      editorState,
      entityType: entityTypeEnum.media,
    });

    const hasMedia = !isEmpty(mediaEntities);

    return hasMedia;
  }

  /*
    This function will add an atomic block representing the attached media.
    If no editor state is given, a new one is created
  */
  static addMediaEntity({
    mediaEntityData,
    focusedEditor = EditorState.createEmpty(),
  }) {
    const editorState = EditorState.createEmpty();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      entityTypeEnum.media,
      draftJsEntityTypeEnum.IMMUTABLE,
      mediaEntityData,
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    /*
      The third arg here must be a non-empty string. It represents the atomic block and will be
      placed in the "text" property in the blocks array for the editorState
    */
    const newEditorStateWithMedia = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      " ",
    );

    /*
      newEditorStateWithMedia will always contain 3 blocks in the following form:
        1. Empty line
        2. Atomic block with attached media
        3. Empty line

      We take newEditorStateWithMediaBlocks[0] (empty line) and newEditorStateWithMediaBlocks[1] (the atomic block) because we want the attachment to appear first.
      We also want whatever content was in focusedEditor to appear immediately after the atomic block (user may have already written some text).
    */

    const newEditorStateWithMediaBlocks = newEditorStateWithMedia
      .getCurrentContent()
      .getBlocksAsArray();
    const focusedEditorBlocks = focusedEditor
      .getCurrentContent()
      .getBlocksAsArray();

    const mergedBlocks = [
      ...newEditorStateWithMediaBlocks.slice(0, 2),
      ...focusedEditorBlocks,
    ];

    const mergedState = EditorState.createWithContent(
      ContentState.createFromBlockArray(mergedBlocks),
    );

    return mergedState;
  }

  static createEditorStateFromHtml({ htmlBody }) {
    const sanitizedHtmlString = sanitizeString(htmlBody);

    const contentState = convertFromHTML({
      htmlToEntity: (nodeName, node, createEntity) => {
        if (
          nodeName === "data" &&
          node.attributes["data-object"]?.value === entityTypeEnum.emv
        ) {
          return createEntity(
            entityTypeEnum.emv,
            draftJsEntityTypeEnum.IMMUTABLE,
            { name: node.attributes["name"].value },
          );
        }
      },
    })(sanitizedHtmlString);

    return EditorState.createWithContent(contentState);
  }

  /*
    This function will retrieve all entities of a given type in an editorState. If no entityType
    is specified, all entities will be returned.
  */
  static getEntities({ editorState, entityType }) {
    const rawEntities = [];
    const contentState = editorState.getCurrentContent();

    contentState.getBlocksAsArray().forEach((block) => {
      block.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (!entityKey) return false;

        const entity = contentState.getEntity(entityKey);
        rawEntities.push({
          type: entity.getType(),
          mutability: entity.getMutability(),
          data: entity.getData(),
        });
      });
    });

    if (entityType) {
      return rawEntities.filter((entity) => entity.type === entityType);
    }

    return rawEntities;
  }

  /*
    Function to check if the given editorState has an attached file
    -> This function should be considered when dealing with editor performance
  */
  static hasFile({ editorState }) {
    return (
      EditorHelper.getEntities({
        editorState,
        entityType: entityTypeEnum.media,
      }).length > 0
    );
  }

  /* Function that returns the number of characters that are in a given editorState */
  static getCharCount({ editorState }) {
    const plainText = editorState.getCurrentContent().getPlainText("");
    return plainText.length;
  }

  /* Responsible for removing a media entity from the given editorState if one exists */
  static removeMediaFromEditorState = ({ editorState }) => {
    const mediaEntities = EditorHelper.getEntities({
      editorState,
      entityType: entityTypeEnum.media,
    });

    const hasMedia = !isEmpty(mediaEntities);
    if (!hasMedia) return editorState;

    const rawData = convertToRaw(editorState.getCurrentContent());
    const { blocks, entityMap } = rawData;

    const newBlocks = blocks.slice(2);
    const newEntityMap = omitBy(
      entityMap,
      (entity) => entity.type === entityTypeEnum.media,
    );

    const finalEditorState = EditorHelper.convertEditorStateFromRaw({
      rawEditorState: { blocks: newBlocks, entityMap: newEntityMap },
    });

    return finalEditorState;
  };

  /*
    Responsible for splitting an editorState into 2 parts, one with the media, and the other with the caption.
    If no media exists, an empty editorState will be returned for the one with media.
  */
  static splitMediaMessageEditorState({ editorState }) {
    const mediaEntity = EditorHelper.getEntities({
      editorState,
      entityType: entityTypeEnum.media,
    })[0];

    if (!mediaEntity) {
      return {
        mediaEditorState: EditorState.createEmpty(),
        captionEditorState: editorState,
      };
    }

    const mediaEditorState = EditorHelper.addMediaEntity({
      mediaEntityData: mediaEntity.data,
    });

    const captionEditorState = EditorHelper.removeMediaFromEditorState({
      editorState,
    });

    return { mediaEditorState, captionEditorState };
  }

  /*
    This function will receive editorState & list of messageTemplateVariables
    Then will return
    - invalidVariableSelectionStates: the selectionState for all the invalid variable(s)
    - invalidVariables: list of invalid variable's name
  */
  static getInvalidMessageTemplateVariablesFromEditorState({
    editorState,
    messageTemplateVariables,
  }) {
    const contentState = editorState.getCurrentContent();
    const invalidVariableSelectionStates = [];
    const invalidVariables = [];

    contentState.getBlocksAsArray().forEach((block) => {
      block.findEntityRanges(
        /* Find entity match condition */
        (character) => {
          const entityKey = character.getEntity();
          if (!entityKey) return false;

          const entity = contentState.getEntity(entityKey);
          const entityType = entity.getType();

          if (entityType !== entityTypeEnum.emv) return false;

          const variable = messageTemplateVariables.find((variable) => {
            return variable.name === entity.getData().name;
          });

          if (variable) return false;

          invalidVariables.push(entity.getData().name);
          return true;
        },
        /* Get entity location */
        (start, end) => {
          const selectionState = SelectionState.createEmpty(block.getKey());

          invalidVariableSelectionStates.push(
            selectionState.merge({
              anchorOffset: start,
              focusOffset: end,
            }),
          );
        },
      );
    });

    return { invalidVariableSelectionStates, invalidVariables };
  }

  /* This function is used to modify the raw data we send to backend */
  static prepareModifiedRawDraftJsDataForBackend = ({ rawEditorState }) => {
    const editorState = EditorHelper.convertEditorStateFromRaw({
      rawEditorState,
    });

    const editorStateWithoutMedia = EditorHelper.removeMediaFromEditorState({
      editorState,
    });

    const rawEditorStateWithoutMedia = convertToRaw(
      editorStateWithoutMedia.getCurrentContent(),
    );

    const rawEditorStateWithTransformedEntities =
      editorHelperUtils.transformEntities(rawEditorStateWithoutMedia);

    const rawEditorStateWithoutTrailingSpaces =
      editorHelperUtils.removeTrailingSpaces(
        rawEditorStateWithTransformedEntities,
      );

    return rawEditorStateWithoutTrailingSpaces;
  };

  /* 
    Responsible for constructing the data that will be given to the atomic block that represents attached
    media in the EditorState
  */
  static getMediaBlockItemInfo({ file, isFromTemplate }) {
    return {
      name: file.name,
      type: isFromTemplate ? file.type : file.type.match(/[a-z]*/)[0],
      src: isFromTemplate ? file.src : URL.createObjectURL(file),
      fileSize: file.size || null,
      file,
    };
  }

  static addMessageTemplateVariableToMessage({ editorState, variable }) {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const contentStateWithEntity = currentContent.createEntity(
      entityTypeEnum.emv,
      draftJsEntityTypeEnum.IMMUTABLE,
      variable,
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      variable.label,
      null,
      entityKey,
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters",
    );

    return newEditorState;
  }

  static checkIfMediaEntityRequiresNewEditor({
    isMediaCaptionAllowed,
    editorState,
  }) {
    const currentEditorHasFile = EditorHelper.hasFile({ editorState });

    /* If editor has media in it, we require a new editor for the item */
    if (currentEditorHasFile) return true;

    const hasText = EditorHelper.isMessageValid({ editorState });

    /* If editor has text but captions are unsupported, we require a new editor*/
    if (hasText && !isMediaCaptionAllowed) return true;
    return false;
  }

  /*
    Function to check if at least one editor state in the editorStates array has
    content in it
  */
  get hasContent() {
    const { editorStates } = this.editor;

    return editorStates.some((editorState) =>
      EditorHelper.isMessageValid({ editorState }),
    );
  }

  /* Check if number of max editors is reached */
  get isEditorLimitReached() {
    return this.editor.editorStates.length >= this.editor.editorLimit;
  }

  get isAttachLimitReached() {
    const { editorStates, fileLimit } = this.editor;
    const fileCount = editorStates.reduce((accumulator, currentValue) => {
      const hasFile = EditorHelper.hasFile({ editorState: currentValue });

      if (hasFile) return accumulator + 1;
      return accumulator;
    }, 0);

    return fileCount >= fileLimit;
  }

  setEditor(editor) {
    if (this.editor.isViewOnly) return;
    this.editor = editor;
  }

  getIsCharCountExceeded({ editorState }) {
    return (
      EditorHelper.getCharCount({ editorState }) > this.editor.characterLimit
    );
  }

  deleteMessage({ pos }) {
    const { editorStates } = this.editor;
    const filteredEditorStates = editorStates.filter(
      (item, index) => index !== pos,
    );

    this.setEditor({
      ...this.editor,
      editorStates: filteredEditorStates,
    });
  }

  changeState({ index, editorState }) {
    const { editorStates } = this.editor;
    const isMultiMessage = editorStates.length > 1;

    if (isMultiMessage) {
      const prevEditorState = editorStates[index];
      if (!prevEditorState) return;

      const isPrevMessageValid = EditorHelper.isMessageValid({
        editorState: prevEditorState,
      });
      const isMessageValid = EditorHelper.isMessageValid({ editorState });

      /*
        If message used to be valid and it no longer is, delete it automatically,
        we check previous state to accommodate for adding empty messages
      */
      if (isPrevMessageValid && !isMessageValid) {
        this.deleteMessage({ pos: index });
        return index;
      }
    }

    /*
      If we have an attachment in the message, ensure the format of the message is maintained
      with these rules:
        1. The first block must always be an empty block
        2. The second block must be the atomic block (the attached media)

      If the new editorState does not conform to these rules, do nut update the state
    */
    const hasFile = EditorHelper.hasFile({ editorState });
    if (hasFile) {
      const blocks = editorState.getCurrentContent().getBlocksAsArray();
      if (blocks[0].getText() !== "") return;
      if (blocks[1].getType() !== blockTypeEnum.atomic) return;
    }

    const newEditorStates = [...editorStates];
    newEditorStates[index] = editorState;

    this.setEditor({ ...this.editor, editorStates: newEditorStates });
  }

  /* Removes a specified block from an editorState  */
  removeBlock({ index, blockKey }) {
    const { editorStates } = this.editor;
    const editorState = editorStates[index];
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const newBlockMap = blockMap.remove(blockKey);
    const newContentState = contentState.merge({
      blockMap: newBlockMap,
    });

    const editorStateWithoutBlock = EditorState.push(
      editorState,
      newContentState,
      editorChangeTypeEnum.removeRange,
    );

    return EditorState.moveFocusToEnd(editorStateWithoutBlock);
  }

  createMediaMessage({ mediaEntityData, currentFocus }) {
    const { editorStates } = this.editor;
    const newEditorState = EditorHelper.addMediaEntity({
      mediaEntityData,
    });
    const insertionIndex = currentFocus + 1;
    const insertionIsAtEnd = insertionIndex >= editorStates.length;
    const newEditorStates = [...editorStates];

    if (insertionIsAtEnd) {
      newEditorStates.push(newEditorState);
    } else {
      newEditorStates.splice(insertionIndex, 0, newEditorState);
    }

    this.setEditor({ ...this.editor, editorStates: newEditorStates });
  }

  /* Creates an new editorState for each messageTemplatePart */
  createEditorStatesFromMessageTemplateParts({ messageTemplateParts = [] }) {
    const editorStates = isEmpty(messageTemplateParts)
      ? [EditorState.createEmpty()]
      : messageTemplateParts.map((messageTemplatePart) => {
          const { mediaMessageTemplatePart, textMessageTemplatePart } =
            messageTemplatePart;

          if (textMessageTemplatePart) {
            return EditorHelper.createEditorStateFromHtml({
              htmlBody: textMessageTemplatePart.body,
            });
          } else if (mediaMessageTemplatePart) {
            return editorHelperUtils.createEditorStateFromMediaMessageTemplatePart(
              { mediaMessageTemplatePart },
            );
          } else {
            return EditorState.createEmpty();
          }
        });

    this.setEditor({ ...this.editor, editorStates });
  }

  /* Handles making message template parts for both create and update message template */
  createMessageTemplatePartsFromEditor({
    mediaUuidArray = [],
    buttonsArray = [],
  }) {
    const { editorStates } = this.editor;

    return editorHelperUtils.constructMessageTemplatePartsFromEditorStates({
      editorStates,
      mediaUuidArray,
      buttonsArray,
    });
  }

  createMessagingProviderSpecificMessageTemplatePartsMap({
    mediaUuidArray = [],
    buttonsArray = [],
    messagingProviders,
    getIsMediaCaptionAllowedForMediaMessageTemplate,
  }) {
    const { editorStates } = this.editor;
    const returnObj = {};

    messagingProviders.forEach((messagingProvider) => {
      const messageTemplateParts = [];

      editorStates.forEach((editorState, index) => {
        const hasFile = EditorHelper.hasFile({ editorState });

        if (!hasFile) {
          const parts =
            editorHelperUtils.constructMessageTemplatePartsFromEditorStates({
              editorStates: [editorState],
              mediaUuidArray: [undefined],
              buttonsArray: [buttonsArray[index]],
            });

          messageTemplateParts.push(...parts);
          return;
        }

        const mediaEntityData = EditorHelper.getEntities({
          editorState,
          entityType: entityTypeEnum.media,
        })[0].data;

        const isMediaCaptionAllowed =
          getIsMediaCaptionAllowedForMediaMessageTemplate({
            messagingProvider,
            fileType: mediaEntityData.type,
          });

        if (isMediaCaptionAllowed) {
          const parts =
            editorHelperUtils.constructMessageTemplatePartsFromEditorStates({
              editorStates: [editorState],
              mediaUuidArray: [mediaUuidArray[index]],
              buttonsArray: [buttonsArray[index]],
            });

          messageTemplateParts.push(...parts);
        } else {
          const { mediaEditorState, captionEditorState } =
            EditorHelper.splitMediaMessageEditorState({ editorState });

          const splitMessageTemplateParts =
            editorHelperUtils.constructMessageTemplatePartsFromEditorStates({
              editorStates: [mediaEditorState, captionEditorState],
              mediaUuidArray: [mediaUuidArray[index], undefined],
              /* TODO: Might need to revise how we attach the buttons when we need to split the message */
              buttonsArray: [buttonsArray[index], []],
            });

          messageTemplateParts.push(...splitMessageTemplateParts);
        }
      });

      returnObj[messagingProvider.id] = messageTemplateParts;
    });

    return returnObj;
  }

  /*
    Attempts to retrieve uuids of media in the order they appear in the editor if
    media exists at all
  */
  getMediaUuids = ({ mediaUploads }) => {
    const { editorStates } = this.editor;
    const mediaIdentifiers =
      editorHelperUtils.getMediaIdentifiers(editorStates);
    const result = {
      isUploadInProgress: false,
      isUploadFailed: false,
      mediaUuidArray: [],
    };

    /* Get status objects for the editorStates in the order they appear */
    const statusArray = editorHelperUtils.getMediaStatusArray({
      mediaIdentifiers,
      mediaUploads,
    });

    const isUploadInProgress = statusArray.some(
      (item) => item.status === fileUploadStatusEnum.inProgress,
    );
    const isUploadFailed = statusArray.some(
      (item) => item.status === fileUploadStatusEnum.failed,
    );

    if (isUploadInProgress) {
      result.isUploadInProgress = true;
    } else {
      if (isUploadFailed) {
        result.isUploadFailed = true;
      } else {
        result.mediaUuidArray = statusArray.map((item) => item.uuid);
      }
    }

    return result;
  };

  addEmptyMessage() {
    if (this.isEditorLimitReached) return;

    this.setEditor({
      ...this.editor,
      editorStates: [...this.editor.editorStates, EditorState.createEmpty()],
    });
  }

  /* This function will remove the invalid variable(s) in editor */
  removeInvalidMessageTemplateVariablesFromEditor({
    messageTemplateVariables,
  }) {
    const newEditorStates = this.editor.editorStates.map((editorState) => {
      let newEditorState = editorState;

      const { invalidVariableSelectionStates } =
        EditorHelper.getInvalidMessageTemplateVariablesFromEditorState({
          editorState,
          messageTemplateVariables,
        });

      /* Must start from the last variable to ensure the correct characters being removed */
      invalidVariableSelectionStates
        .reverse()
        .forEach((invalidVariableSelectionState) => {
          const contentState = newEditorState.getCurrentContent();

          const newContentState = Modifier.removeRange(
            contentState,
            invalidVariableSelectionState,
            "backward",
          );

          newEditorState = EditorState.set(newEditorState, {
            currentContent: newContentState,
          });
        });

      return newEditorState;
    });

    this.setEditor({ ...this.editor, editorStates: newEditorStates });
  }
}

export default EditorHelper;
