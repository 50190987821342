export const voiceCallFailureDetailEnum = Object.freeze({
  SUBSCRIBER_UNAVAILABLE: "SUBSCRIBER_UNAVAILABLE",
  CARRIER_DECLINED: "CARRIER_DECLINED",
  CANNOT_ROUTE: "CANNOT_ROUTE",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  NUMBER_OUT_OF_SERVICE: "NUMBER_OUT_OF_SERVICE",
  INVALID_NUMBER: "INVALID_NUMBER",
  CARRIER_TIMEOUT: "CARRIER_TIMEOUT",
  BLOCKED_NUMBER: "BLOCKED_NUMBER",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
});

export const voiceCallFailureDescriptionLabel = Object.freeze({
  [voiceCallFailureDetailEnum.SUBSCRIBER_UNAVAILABLE]:
    "The subscriber is temporarily unavailable. Please try again later.",
  [voiceCallFailureDetailEnum.CARRIER_DECLINED]:
    "The call was rejected by carrier. Please check if the destination number is correct.",
  [voiceCallFailureDetailEnum.CANNOT_ROUTE]:
    "The destination number is not supported or is blocked for this account. Please check if the number is correct.",
  [voiceCallFailureDetailEnum.INTERNAL_ERROR]: "An error occurred upstream.",
  [voiceCallFailureDetailEnum.NUMBER_OUT_OF_SERVICE]:
    "The number is out of service. Please try again later.",
  [voiceCallFailureDetailEnum.INVALID_NUMBER]:
    "The source or destination number is invalid. Please check if the number is correct.",
  [voiceCallFailureDetailEnum.CARRIER_TIMEOUT]:
    "The carrier did not respond within the timeout period.",
  [voiceCallFailureDetailEnum.BLOCKED_NUMBER]:
    "The destination number is blocked by the admin. Please check if the number is correct or contact your admin.",
  [voiceCallFailureDetailEnum.UNKNOWN_ERROR]: "An unknown error occurred.",
});
